const baseUrl = "https://hub-api.nova-seo.com/";
const baseUrlSpa = window.location.origin + "/";

export const api = {
	auth: {
		getCode 	: () => {
			var builder = new URL("https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount");
			builder.searchParams.append("scope", "email");
			builder.searchParams.append("access_type", "online");
			builder.searchParams.append("redirect_uri", baseUrlSpa + 'redirect-google-auth');
			builder.searchParams.append("response_type", "code");
			builder.searchParams.append("client_id", "1085961677711-lovttm0u02l8m0nmi2lcrdkd18qh4b32.apps.googleusercontent.com");
			builder.searchParams.append("flowName", "GeneralOAuthFlow");
			return builder.href;
		},
		getToken 	: () => baseUrl + `auth/google?redirect_uri=${baseUrlSpa}redirect-google-auth`,
		regenToken 	: () => baseUrl + 'auth/renew'
	},
	formulaires: {
		getBugListType			: () => baseUrl + "jira-sd/bug-technique/list-type",
		postBug 				: () => baseUrl + "jira-sd/bug-technique",
		getTicketListMotif		: () => baseUrl + "jira-sd/new-ticket/list-objet",
		postTicket				: () => baseUrl + "jira-sd/new-ticket",
		getEnvoieMasseListMotif	: () => baseUrl + "jira-sd/envoie-masse/list-objet",
		postEnvoieMasse			: () => baseUrl + "jira-sd/envoie-masse",
		postSendCdcPapier 		: () => baseUrl + "jira-sd/cdc-papier",
		postSendRapportSuivi 	: () => baseUrl + "jira-sd/rapport-suivi",
		postSendCommentaire 	: () => baseUrl + "jira-sd/demande/commentaire",
		searchJiraDemandes 		: () => baseUrl + "jira-sd/demande",
		getFicheParam 			: () => baseUrl + "intranet/fiche-param/",
		getAdminListMotif		: () => baseUrl + "intranet/send-service-admin/list-motif",
		postAdminInterne 		: () => baseUrl + "intranet/send-service-admin-interne",
		postAdminClient 		: () => baseUrl + "intranet/send-service-admin-client",
		postJuridique 			: () => baseUrl + "intranet/send-service-juridique",
		searchJiraDemande 		: (v) => baseUrl + `jira-sd/demande/${v}`,
		searchJiraDemandeSLA	: (v) => baseUrl + `jira-sd/demande/sla/${v}`,
		cabSearch 				: (v) => baseUrl + "intranet/cab-search/" + v,
		sendFile 				: (v) => {
			var builder = new URL(baseUrl + "file/proxy");
			builder.searchParams.append("name", v);
			return builder.href;
		}
	},
	externe: {
		getTutoriels 			: () => "https://www.youtube.com/channel/UCJVuTWNsVTNXzonG7nK179Q"
	}
}